// extracted by mini-css-extract-plugin
export var artworkImg = "gnaj-module--artwork-img--uSWLv";
export var bodyCol = "gnaj-module--body-col--tS-JT";
export var copyright = "gnaj-module--copyright--SO5-7";
export var credits = "gnaj-module--credits--b2ucA";
export var hl = "gnaj-module--hl--UzTix";
export var lnkContainer = "gnaj-module--lnk-container--Hqb4a";
export var lnkWrapper = "gnaj-module--lnk-wrapper--pfd4w";
export var logo = "gnaj-module--logo--dDyS1";
export var streamIco = "gnaj-module--stream-ico--DBvXn";
export var streamIcoDisabled = "gnaj-module--stream-ico-disabled--Y9WVS";
export var streamList = "gnaj-module--stream-list--I3UZe";
export var titl = "gnaj-module--titl--mc8xc";